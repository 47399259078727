<template>
  <b-card>
    <!-- about -->
    <div>
      <h5 class="text-capitalize mb-75">
        {{ $t('ABOUT') }}
      </h5>
      <b-card-text>
        {{ user.about_me }}
      </b-card-text>
      <h5 class="text-capitalize mb-75">
        {{ $t('JOINED') }}
      </h5>
      <b-card-text>
        {{ formatDate(user.created_timestamp) }}
      </b-card-text>
      <h5 class="text-capitalize mb-75">
        {{ $t('ROLE') }}
      </h5>
      <b-card-text>
        {{ resolveUserRole(user.userRoleId).name }}
      </b-card-text>
      <h5 class="text-capitalize mb-75">
        {{ $t('EMAIL_ADDRESS') }}
      </h5>
      <b-card-text>
        {{ user.email }}
      </b-card-text>
      <h5 class="text-capitalize mb-75">
        {{ $t('PHONE_NUMBER') }}
      </h5>
      <b-card-text>
        {{ user.phonenumber }}
      </b-card-text>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCardText } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import { resolveUserRole } from '@/constants/utils'

export default {
  components: {
    BCard,
    BCardText,
  },
  mixins: [mixinDate],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      resolveUserRole,
    }
  },
}
</script>
